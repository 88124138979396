<template>
  <v-row>
    <v-col cols="12">
      <!-- <steppers-campaign :step="3"></steppers-campaign> -->
      <checkout-item></checkout-item>
    </v-col>
  </v-row>
</template>

<script>
import CheckoutItem from './components/Checkout.vue'
import SteppersCampaign from './components/Steppers.vue'

export default {
  components: {
    CheckoutItem,
    SteppersCampaign
  },
  setup() {
    return {}
  },
}
</script>
