<template>
  <div>
    <p class="headline black--text font-weight-bold">Checkout</p>
    <div v-if="isLoading">
      <div class="d-flex justify-center">
        <v-skeleton-loader
          v-for="n in 2" :key="n"
            class="mx-auto pa-1"
            width="600"
            type="card"
          ></v-skeleton-loader>
      </div>
      <br>
      <div class="d-flex justify-center">
        <v-skeleton-loader
            class="mx-auto pa-1"
            width="1200"
            type="card"
          ></v-skeleton-loader>
      </div>
    </div>
    <div v-else>
      <v-row class="mt-0">
        <div class="col-md-8 col-lg-8">
          <v-card class="overflow-hidden elevation-1 mb-5"  v-for="list in itemData" :key="list.cartId">
            <!-- <v-card-title>List Item</v-card-title> -->
            <v-card-text>
              <!-- <v-divider class="mb-3"></v-divider> -->
              <!-- <div class="row">
                <div class="col-md-6">
                  <p class="d-flex align-center mb-0 font-weight-bold black--text">{{ list.name }}</p><br>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="image" class="primary--text text-24 mr-2"></b-icon>
                    Format <strong class="ml-1">Feed Photo</strong></p><br>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="calendar-3" class="primary--text text-24 mr-2"></b-icon>
                    Durasi <strong class="ml-1">4 Minggu</strong></p>
                </div>
                <div class="col-md-6">
                  <p class="d-flex align-center mb-6">
                    <b-icon icon="bar-chart" class="white--text text-24 mr-2"></b-icon>
                    <span class="ml-0 white--text">Level <strong class="ml-1">Nano</strong></span>
                  </p>
                  <p class="d-flex align-center mb-6">
                    <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon>
                    <span class="ml-0">Level <strong class="ml-1">Nano</strong></span>
                  </p>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="person" class="primary--text text-24 mr-2"></b-icon>
                    <span class="ml-0">Jumlah <strong class="ml-1">20 Influencer</strong></span>
                  </p>
                </div>
              </div> -->
              <div class="row">
                <div class="col-md-6">
                  <p class="d-flex align-center mb-0 font-weight-bold">{{ list.name }}</p><br>
                  <p class="d-flex align-center mb-0">
                    <i class="bi bi-person-workspace"></i>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-person-workspace primary--text mr-2" viewBox="0 0 16 16">
                      <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H4Zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"/>
                      <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.373 5.373 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2H2Z"/>
                    </svg>
                  <span class="ml-1 text-capitalize">{{ list.description | labelFormat }}</span></p><br>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="image" class="primary--text text-24 mr-2"></b-icon>
                    <span class="ml-1 text-capitalize">{{ list.content }}</span></p><br>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="calendar-3" class="primary--text text-24 mr-2"></b-icon>
                    <span class="ml-1">{{ list.duration }}</span></p>
                </div>
                <div class="col-md-6">
                  <div class="d-flex justify-end mb-6" v-if="!isMobile()">
                    <p class="font-weight-bold mb-0"><strong class="white--text">Rp {{ list.price | numberFormat }}</strong></p>
                  </div>
                  <p class="d-flex align-center mb-6">
                    <b-icon icon="bar-chart" class="primary--text text-24 mr-2"></b-icon>
                    <span class="">Nano</span>
                  </p>
                  <p class="d-flex align-center mb-0">
                    <b-icon icon="person" class="primary--text text-24 mr-2"></b-icon>
                    <span class="">{{ list.qty }} Influencer</span>
                  </p>
                </div>
              </div>
              <br>
              <v-divider class="mb-3"></v-divider>
              <div class="row">
                <div class="col-md-12">
                  <p class="font-weight-bold mb-0">Subtotal <strong style="float: right" class="secondary--text">Rp {{ list.price | numberFormat }}</strong></p>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div class="col-md-4">
          <v-card class="elevation-1">
            <v-card-title>Ringkasan Item</v-card-title>
            <v-list two-line>
              <template>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >Total Harga (<span>{{this.itemData.length}} </span>Item)</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="subtitle-2">Rp {{total | numberFormat}}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title >PPN</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text class="subtitle-2">Rp {{ serviceFee | numberFormat}}</v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title class="font-weight-bold">Total Tagihan</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-list-item-action-text><h2>Rp {{totalPrice | numberFormat}}</h2></v-list-item-action-text>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-list>
            <div class="row ml-1 mr-1">
              <div class="col-md-12">
              <v-btn
                color="primary"
                class="text-capitalize"
                block
                @click="choosePayment"
                >
                <!-- <b-icon icon="cart3" class="white--text text-24 mr-2"></b-icon>  -->
                <i class="fas fa-credit-card text-24 mr-2"></i>
                Pilih Pembayaran
              </v-btn>
              </div>
          </div>
          </v-card>
        </div>
      </v-row>
    </div>
    <v-dialog
      v-model="alert"
      max-width="400"
    >
      <v-alert type="error">
        {{alertMessage}} <button style="float:right" type="button" v-on:click="closeAlert" class="close right" data-dismiss="alert-success" aria-label="Close"><span aria-hidden="true">&times;</span></button>
      </v-alert>
    </v-dialog>
    <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
      v-model="paymentDialog"
      style="z-index: 1000;"
    >
      <template v-slot:default="dialog">
        <v-card>
          <v-toolbar
            color="primary"
          ><span class="white--text font-weight-bold pl-1">Pilih Pembayaran</span></v-toolbar>
          <v-card-text>
            <!-- <div class="text-h2 pa-12">Hello world!</div> -->
            <div class="body-1 black--text font-weight-bold mt-5">Metode Pembayaran</div>
            <v-radio-group v-model="selectedPayment">
              <v-list>
                <v-list-item class="pl-0 pr-0" @click="selectPayment('bca_transfer')">
                  <v-list-item-icon>
                    <v-img class="mt-2" src="@/assets/images/logos/bank_bca.png" max-height="40" max-width="60"></v-img>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Transfer</v-list-item-title>

                    <v-list-item-subtitle>Bank BCA</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <!-- <v-btn icon>
                      <i class="fas fa-credit-card text-24 mr-2"></i>
                    </v-btn> -->
                    <!-- <v-checkbox
                      v-model="selected"
                      value="tf_bca"
                    ></v-checkbox> -->
                    <v-radio
                      value="bca_transfer"
                    ></v-radio>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
                <v-list-item class="pl-0 pr-0"  @click="selectPayment('bni_transfer')" disabled>
                  <v-list-item-icon>
                    <v-img class="mt-2" src="@/assets/images/logos/bank_bni.png" max-height="40" max-width="60"></v-img>
                  </v-list-item-icon>

                  <v-list-item-content>
                    <v-list-item-title class="subtitle-1">Transfer (Cooming Soon)</v-list-item-title>

                    <v-list-item-subtitle>Bank BNI</v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-radio
                      value="bni_transfer"
                    ></v-radio>
                  </v-list-item-action>
                </v-list-item>
                <v-divider inset></v-divider>
              </v-list>
            </v-radio-group>
            <div class="body-1 black--text font-weight-bold mt-5">Ringkasan Pembayaran</div>
            <v-list>
              <v-list-item class="pl-0 pr-0">
                <v-list-item-content>
                  <v-list-item-subtitle>Subtotal</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="">Rp {{total | numberFormat}}
                </v-list-item-action>
              </v-list-item>
              <v-list-item class="pl-0 pr-0">
                <v-list-item-content>
                  <v-list-item-subtitle>PPN</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="">Rp {{serviceFee | numberFormat}}
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <v-list-item class="pl-0 pr-0">
                <v-list-item-content>
                  <v-list-item-subtitle class="font-weight-bold black--text body-1">Total Tagihan</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action class="font-weight-bold black--text body-1">Rp {{totalPrice | numberFormat}}
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="justify-end">
            <v-btn
              color="error"
              @click="dialog.value = false"
              class="text-capitalize"
            >Batal</v-btn>
            <v-btn
              color="primary"
              @click="submitPayment"
              class="text-capitalize"
            >Bayar</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row justify="center">
      <v-dialog
        v-model="loadingCheckout"
        persistent
        max-width="350"
      >
        <v-card>
          <div class="d-flex justify-center pa-10">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import axios from 'axios'

export default defineComponent({
  components: {
  },
  props:['selectedCount'],
  setup() {
    return{
      item:'',
      bpm: 1,
      alert:false,
      alertMessage:'',
      paymentDialog:false,
      loadingCheckout:false,
      influencerPrice:20000,
      totalPrice:0,
      selected:0,
      selectedPayment:'bca_transfer',
      itemData:[],
      itemCheckout:'',
      isLoading:false,
      checkoutNow:'',
      total:0,
      serviceFee:0,
      cart:'',
      influencer:'',
      package:'',
      packageType:'',
      checkoutType:'CART'
    }
  },
  mounted(){
    this.item = this.$route.query.item
    this.itemCheckout = localStorage.getItem('checkout') ? JSON.parse(localStorage.getItem('checkout')):''
    this.checkoutNow = localStorage.getItem('checkoutNow')
    console.log(this.itemCheckout)
    if(this.checkoutNow === 'true'){
      if(this.itemCheckout === ''){
        this.$router.push({name:'campaign'});
      } else {
        this.loadOneOrder()
        this.influencer = this.itemCheckout.influencer_id
        this.package = this.itemCheckout.package_id
        this.packageType = this.itemCheckout.type
        this.checkoutType = 'CHECKOUT'
      }
    } else {
      this.checkoutType = 'CART'
      this.loadOrders()
    }
  },
  methods:{
    loadOneOrder(){
      this.isLoading = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'packages/onePackage', {id:this.itemCheckout.package_id, type:this.itemCheckout.type}, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          if(response.status === 201){
            let data = response.data
            if(this.itemCheckout.type === 'custom'){
              data.description = response.data.type
              data.type = response.data.level
              data.content = response.data.format
            }
            this.itemData.push(data)
            for(let a = 0; a <this.itemData.length; a++){
              this.total += parseInt(this.itemData[a].price)
            }
            this.serviceFee = this.total * (10 / 100);
            this.totalPrice = this.total + this.serviceFee
            setTimeout(() => { 
              this.isLoading = false
            }, 200);
          }
        }).catch(error => {
          console.log(error)
      });
      // axios.get(
      //   process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/getOrders', {headers: {
      //     'Authorization': 'Bearer '+ token
      //   }}).then(response => {
      //     if(response.status === 200){
      //       this.item = response.data.data
      //       console.log(this.item)
      //       setTimeout(() => { 
      //         this.isLoading = false
      //       }, 500);
      //     }
      //   }).catch(error => {
      //     console.log(error)
      // });
    },
    loadOrders(){
      this.isLoading = true;
      if(this.itemCheckout.length > 0){
        for(let a = 0; a < this.itemCheckout.length; a++){
          this.itemCheckout[a].package.cartId = this.itemCheckout[a].cart.id 
          this.itemData.push(this.itemCheckout[a].package)
          this.cart += this.itemCheckout[a].cart.id+','
        }
        for(let a = 0; a <this.itemData.length; a++){
          this.total += parseInt(this.itemData[a].price)
        }
        this.serviceFee = this.total * (10 / 100);
        this.totalPrice = this.total + this.serviceFee
      }
      this.isLoading = false;
    },
    choosePayment(){
      this.paymentDialog = true;
    },
    selectPayment(val){
      this.selectedPayment = val;
    },  
    encryptData(val) {
      let secretKey = 'U2FsdGVkX19Om4wikNyowykfw0xVZpvjyfZbNH/t5xg='
      return this.$CryptoJS.AES.encrypt(val.toString(), secretKey).toString();
    },
    submitPayment(){
      this.paymentDialog = false;
      this.loadingCheckout = true;
      let token = localStorage.getItem('token')
      axios.post(
        process.env.VUE_APP_API_ENDPOINT + 'requestCampaign/addToOrders', {
          cart_id:this.cart, 
          subtotal:this.total,
          total:this.totalPrice,
          payment_method:this.selectedPayment,
          type: this.checkoutType,
          influencer_id:this.influencer,
          package_id: this.package,
          package_type: this.packageType
        }, {headers: {
          'Authorization': 'Bearer '+ token
        }}).then(response => {
          console.log(response)
          if(response.status === 201){
            let id = response.data.data
            setTimeout(() => { 
              this.loadingCheckout = false
              localStorage.setItem('checkout', '')
              this.$router.push({name:'campaign-payment-detail', query: {order: this.encryptData(id)}});
            }, 500);
          }
        }).catch(error => {
          console.log(error)
      });
    },
    closeAlert(){
      this.alert = false
    },
    isMobile() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true
      } else {
        return false
      }
    }
  },
  filters: {
    numberFormat (value) {
      return Intl.NumberFormat().format(value).replace(/,/g,'.');
    },
    labelFormat (value) {
      return value.replace(/-/g,' ');
    }
  },
})
</script>

<style scoped>
    .centered-input >>> input {
      text-align: center;
    }
</style>